import React from 'react'
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Ale2 from '../images/Ale2.jpg'
import Ale3 from '../images/Ale3.jpg'
import Ale4 from '../images/Ale4.jpg'
import Ale5 from '../images/Ale5.jpg'
import AleLogo from '../images/AleLogo.png'
// import Video from '../images/aleVideo.jpg'

export default () => (
  <div id="app" className="workpage">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Carolina Ale House Page"></meta>
          <title>Carolina Ale House | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/carolina-ale-house/" />
        </Helmet>
      <PreHeader/>
      <Header/>
        <div className="company_heading">
          <span className="company_heading--name">CAROLINA ALE HOUSE + WRALDS</span>
          <h1 className="company_heading--tagline">
          Helping Carolina Ale House prove it’s the best place for food, sports, and fun in the southeast.
          </h1>
        </div>
        <img className="company_gallery--image" src = {Ale3} alt = "Carolina Ale House "/>
        <div className="company_grid">
          <div className="company_overview">
            <div className="company_overview--heading">OVERVIEW</div>
            <p className="company_overview--text">
              Carolina Ale House is the place to go for great food,
              your favorite games, the best drinks, and a good time.
              To help get the word out about this restaurant that feels like
              both a favorite neighborhood bar and an authentic restaurant we did:
            </p>
            <ul className="company_overview--list">
              <li>SEO</li>
              <li>PPC</li>
              <li>Owned and Operated Media Strategy and Implementation</li>
              <li>Programmatic Strategy and Implementation</li>
              <li>Waze Advertising</li>
            </ul>
          </div>
          <img className="company_logo" src = {AleLogo} alt ="Carolina Ale House service company logo"/>

          <div className="company_gallery">
            <img className="company_gallery--image three" src = {Ale5} alt ="Carolina Ale House "/>
            <img className="company_gallery--image taller" src = {Ale4} alt ="Carolina Ale House "/>
            <img className="company_gallery--image three" src = {Ale2} alt ="Carolina Ale House "/>
            {/* <img className="company_gallery--image video" src = {Video} alt ="Carolina Ale House video"/> */}
          </div>
        </div>
    <Footer/>
  </div>
)
